<template>
    <div>
        <div class="col-12 col-lg-12 section-4 ">
            <div class="custom-border"></div>
            <div class="fl-x fl-a-c fl-eq h-100">
                <div class="pos-r p-3">
                    <div class="p-all">
                        <div class="section-5 mt-2 mb-5">
                            <img class="text-center" src="../../assets/web/login/logoo.png">
                        </div>
                        <div class="fl-x-cc">
                            <div class="glass-panel h-100 col-lg-3 dg-sm-mt">
                                <div class="tickk fl-x-cc mt-3">
                                    <img src="../../assets/img/web/about-us/success-unscreen (2).gif">
                                </div>
                                <div class="section-6 mt-4 ">
                                    <h2 class="font-lato-semi-bold fs-4 mb-3">Payment Success !</h2>

                                </div>
                                <div class=" fl-x-cc mb-5 mt-4">
                                    <btn border-radius="0" class="c-btn-payment" text="Done"></btn>

                                </div>
                                <!--                        <div class="mb-6  custom-center">-->
                                <!--                            <hr>-->
                                <!--                        </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Payment-succes-screen'
};
</script>

<style scoped lang="scss">
.glass-panel {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    border-radius: 0px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 2px;
    backdrop-filter: blur(10px);
    border-radius:6px;

}

.tickk img {
    width: 150px;
    height: 150px;
    //height:100px;
    border-radius: 75%;
}

.sec {
    background-color: white;
}

.lg-no-dis {
    display: none;
}

.sec-1 {
    text-align: center;

}

.sec-left {
    margin-left: 136px;
}

.sec-left1 {
    margin-left: 340px;
    position: relative;
    top: -11px;
}

.section-3 {
    text-align: center;
}

.section-4 {
    background-image: url("../../assets/web/login/login.jpg");
    background-position: center 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

}

.social-bg {
    background-image: url('../../assets/web/login/bgi-new.png');
    background-size: cover;
    background-position: 0% 70%;
    @media (min-width: 768px) and (max-width: 1024px) {
        background-position: 26% 70% !important;
    }
}

.section-5 img {
    width: 215px;
    height: 105px;

}

.section-5 {
    display: flex;
    color: white;
    justify-content: center;
}

.section-6 {
    text-align: center;
    color: white;

}

.try img {
    height: 100px;
    width: 600px;
}

.custom-border {
    border: 0.5px solid #ffffff;
    border-radius: 7px;
    position: absolute;
    top: 2%;
    right: 2%;
    left: 2%;
    bottom: 2%;

}

.p-all {
    padding: 2rem;
}

hr {
    border-top: 2px solid white;
    width: 15%;
}

.custom-center {

    display: flex;
    justify-content: center;
}

h6 {

    margin: 20px auto;
    margin-bottom: 0;
    color: black;
    text-align: center;
    font-size: 12px;
    /*max-width: 360px;*/
    max-width: 280px;
    position: relative;
}

h6:before {
    content: "";
    display: block;
    /*width: 130px;*/
    width: 80px;
    height: 0.5px;
    background: black;
    left: 0;
    top: 50%;
    position: absolute;
}

//.container {
//    min-height: 100vh;
//
//}

h6:after {
    content: "";
    display: block;
    /*width: 130px;*/
    width: 80px;
    height: 0.5px;
    background: black;
    right: 0;
    top: 50%;
    bottom: 0;
    position: absolute;
}

.fb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.gugl {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.strip img {
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0px;
    /*top: 565px;*/
    bottom: 0px;
}

.nobs {
    display: none;
}

@media (max-width: 750px) {
    .try img {
        height: 200px;
        width: 400px;
        width: 400px;
    }

    .nobs {
        display: contents;
    }

    .nobs-no {
        display: none;
    }

    .custom-border {
        border: 0.5px solid #ffffff;
        border-radius: 7px;
        position: relative;
        top: 2%;
        right: 2%;
        left: 2%;
        bottom: 2%;

    }

    .sec-left1 {
        margin-left: 195px;
        position: relative;
        top: -11px;
    }

    .sm-res {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .d-sm-none {
        display: none;
    }

    .lg-no-dis {
        display: contents;
    }

    .set-im img {
        width: 206px;
        height: 108px;
        margin-top: 3rem;
        margin-left: -10rem;

    }

    .sec-left {
        margin-left: 49px;
    }

    .sec-left1 {
        margin-left: 195px;
    }

    //.login-page {
    //    background-color: whitesmoke;
    //}

    .strip img {
        /*width: 642px;*/
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0px;
        top: 848px;
        bottom: 0px;
    }
}

</style>
